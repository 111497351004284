html, body {
  width: 100%; }


body {
  font-size: 16px;
  font-family: 'mplus-1p-regular',$sans-serif;
  // mincho
  // font-family: $serif
  line-height: 1.4;
  position: relative;
  color: $text-color;
  background: $background-color;
  word-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

a {
  text-decoration: none; }

@font-face {
  font-family: 'mplus-1p-regular';
  src: url('http://mplus-webfonts.sourceforge.jp/mplus-1p-regular.eot');
  src: url('http://mplus-webfonts.sourceforge.jp/mplus-1p-regular.eot?#iefix') format('embedded-opentype'),url('http://mplus-webfonts.sourceforge.jp/mplus-1p-regular.woff') format('woff'),url('http://mplus-webfonts.sourceforge.jp/mplus-1p-regular.ttf') format('truetype'); }
