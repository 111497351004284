html, body {
  width: 100%;
}

body {
  font-size: 16px;
  font-family: "mplus-1p-regular", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Hiragino Kaku Gothic ProN", "Yu Gothic", sans-serif;
  line-height: 1.4;
  position: relative;
  color: #333;
  background: #fff;
  word-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: 'mplus-1p-regular';
  src: url("http://mplus-webfonts.sourceforge.jp/mplus-1p-regular.eot");
  src: url("http://mplus-webfonts.sourceforge.jp/mplus-1p-regular.eot?#iefix") format("embedded-opentype"), url("http://mplus-webfonts.sourceforge.jp/mplus-1p-regular.woff") format("woff"), url("http://mplus-webfonts.sourceforge.jp/mplus-1p-regular.ttf") format("truetype");
}

*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

html {
  box-sizing: border-box;
  cursor: default;
  font-family: sans-serif;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

body {
  margin: 0;
}

h1 {
  font-size: 2em;
  margin: .67em 0;
}

figcaption,
figure,
main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

nav ol,
nav ul {
  list-style: none;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ffff00;
  color: #000000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

::-moz-selection {
  background-color: #b3d4fc;
  color: #000000;
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  color: #000000;
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg {
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

[hidden] {
  display: none;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-hidden="false"][hidden] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[aria-hidden="false"][hidden]:focus {
  clip: auto;
}

[aria-disabled] {
  cursor: default;
}

html, body {
  margin: 0;
  height: 100%;
  color: #686868;
}

header, footer {
  z-index: 2;
}

header a:link, footer a:link {
  color: #686868;
}

header a:visited, footer a:visited {
  color: #686868;
}

header a:hover, footer a:hover {
  color: #E2A0E6;
}

h1 {
  font-size: 20px;
  color: #686868;
  padding: 40px;
  margin: 0;
  font-weight: normal;
}

h1::after {
  content: ' ';
  position: absolute;
  display: block;
  margin-top: 5px;
  left: 50%;
  width: 50px;
  height: 3px;
  background-color: #E2A0E6;
  margin-left: -25px;
  z-index: 0;
}

h2 {
  font-size: 20px;
  color: #686868;
  padding: 40px;
  margin: 0;
  font-weight: normal;
}

h2::after {
  content: ' ';
  display: block;
  position: absolute;
  margin-top: 5px;
  left: 50%;
  width: 50px;
  height: 3px;
  background-color: #E2A0E6;
  margin-left: -25px;
  z-index: -1;
}

.h2-body {
  display: inline-block;
  background-color: rgba(246, 246, 246, 0.5);
  width: 100%;
}

small {
  font-size: 13px;
}

.wrap {
  width: 100%;
  min-height: calc(100% - 60px);
}

.wrap::before,
.wrap::after {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  z-index: -2;
}

.wrap::before {
  background: rgba(255, 255, 255, 0.1);
  background-size: cover;
}

.wrap::after {
  background: url("../images/aporo_background.jpg");
  opacity: 0.2;
  background-size: cover;
}

.main {
  padding-top: 60px;
  padding-bottom: 30px;
  width: 100%;
  margin: auto;
  text-align: center;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.link-invaild {
  color: #686868;
  opacity: 0.4;
  font-size: 13px;
}

.link-invaild :hover {
  color: #686868;
}

.page-header {
  width: 100%;
  height: 60px;
  display: inline-block;
  padding-top: 15px;
}

.page-header a img {
  height: 30px;
  vertical-align: middle;
  margin-left: 30px;
  margin-right: 30px;
}

.page-header-menu {
  text-align: center;
  width: 80%;
}

@media screen and (max-width: 800px) {
  .page-header-menu {
    display: none;
  }
}

.page-header-menu li {
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  color: #4A4A4A;
  padding-left: 15px;
}

.page-header-menu-active {
  border-bottom: solid 3px #E2A0E6;
  padding-bottom: 5px;
}

.header-container {
  position: fixed;
  background: #FFFFFF;
  box-shadow: 1px 1px 3px #dadada;
}

.headline {
  text-align: center;
  background-color: rgba(246, 246, 246, 0.7);
  margin-bottom: 26px;
  padding: 13px 0;
  box-shadow: 1px 1px 3px #dadada;
}

.headline-box {
  display: flex;
  align-items: baseline;
  width: 95%;
  max-width: 800px;
  margin: auto;
}

.headline-date,
.headline-link {
  font-size: 14px;
}

@media screen and (max-width: 500px) {
  .headline-date,
  .headline-link {
    font-size: 12px;
  }
}

.headline-title,
.headline-link {
  margin-left: 13px;
}

.headline-title {
  max-width: calc(100% - 85px - 40px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media screen and (max-width: 500px) {
  .headline-title {
    max-width: calc(100% - 75px - 30px);
    font-size: 14px;
  }
}

.headline-link {
  color: #E2A0E6;
}

.headline-link:hover {
  text-decoration: underline;
}

.responsive-menu {
  display: none;
  position: fixed;
  height: 100%;
  margin-top: 60px;
  padding: 5%;
  float: right;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  z-index: 20;
}

.responsive-menu table {
  border-collapse: collapse;
  margin: auto;
  max-width: 600px;
  max-height: 600px;
  display: inline-block;
}

.responsive-menu td {
  text-align: center;
  border: solid 1px white;
  border-collapse: collapser;
  width: 30vw;
  height: 30vw;
}

@media screen and (orientation: landscape) {
  .responsive-menu td {
    width: 23vh;
    height: 23vh;
    font-size: 14px;
  }
}

.responsive-menu td a {
  width: 100%;
  height: 100%;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.responsive-menu td a:link {
  color: white;
}

.responsive-menu td a:visited {
  color: white;
}

.responsive-menu td a:hover,
.responsive-menu td a.active {
  background-color: #808080;
  opacity: 0.9;
  color: #fff;
}

.responsive-icon {
  display: none;
}

@media screen and (max-width: 800px) {
  .responsive-icon {
    display: inline-block;
    height: 40px;
    float: right;
    margin-right: 20px;
  }
}

.IsDisplay {
  display: inline;
  margin-left: 0px;
}

.IconActive {
  background-color: rgba(104, 104, 104, 0.21);
}

footer {
  height: 30px;
  width: 100%;
}

.footer-container {
  background: white;
  width: 100%;
  padding: 2em 0 1em;
  line-height: 30px;
  text-align: center;
  font-size: 13px;
}

.push {
  height: 30px;
}

@media screen and (max-width: 800px) {
  .on_pc {
    display: none;
  }
}

.on_tb {
  display: none;
}

@media screen and (max-width: 800px) {
  .on_tb {
    display: inherit;
  }
}

@media screen and (max-width: 500px) {
  .on_tb {
    display: none;
  }
}

.on_sp {
  display: none;
}

@media screen and (max-width: 500px) {
  .on_sp {
    display: inherit;
  }
}

.calender-info-text {
  display: inline-block;
  max-width: 800px;
  width: 100%;
  padding: 10px;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .calender-info-text {
    text-align: center;
  }
}

.calender-wrap {
  width: 95%;
  max-width: 800px;
  height: 800px;
  margin: auto;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .calender-wrap {
    max-width: 500px;
    height: 500px;
  }
}

@media screen and (max-width: 500px) {
  .calender-wrap {
    height: 400px;
  }
}

.calender-content {
  width: 80%;
  height: 800px;
  max-width: 800px;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .calender-content {
    width: 90%;
    height: 800px;
  }
}

.contact-wrap {
  display: inline-block;
  font-size: 0;
  width: 80%;
  text-align: center;
  max-width: 1260px;
  font-size: 14px;
}

.contact-text {
  text-align: left;
  width: 100%;
  margin: 0 auto;
  max-width: 550px;
}

.wpcf7-form {
  text-align: center;
}

.wpcf7-form-control-wrap {
  top: 5px;
}

.wpcf7-form p {
  padding: 5px;
  padding-top: 20px;
  text-align: left;
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
}

.wpcf7-form p label {
  font-size: 14px;
  text-align: left;
}

.wpcf7-textarea, .wpcf7-text, .wpcf7-number {
  padding: 6px 10px;
  width: 100%;
  border: 1px solid #dadada;
  background: rgba(255, 255, 255, 0.8);
}

.wpcf7-form input[type="text"]:focus,
.wpcf7-form input[type="number"]:focus,
.wpcf7-form input[type="email"]:focus,
.wpcf7-form input[type="tel"]:focus,
.wpcf7-form textarea:focus {
  outline: 1px solid #E2A0E6;
}

input[type="radio"] {
  display: none;
}

.wpcf7-list-item-label {
  position: relative;
  margin-right: .5em;
  padding-left: 1.5em;
}

.wpcf7-list-item-label::before {
  content: "";
  display: block;
  position: absolute;
  top: .2em;
  left: 0;
  width: .8em;
  height: .8em;
  border-radius: 50%;
  z-index: -1;
  transform: scale(0, 0);
  background: rgba(226, 160, 230, 0.3);
}

input[type="radio"]:checked + .wpcf7-list-item-label:before {
  animation: pulsate .8s ease-out;
}

@keyframes pulsate {
  0% {
    transform: scale(0, 0);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(3.5, 3.5);
    opacity: 0.0;
  }
}

.wpcf7-list-item-label::after {
  content: "";
  display: block;
  position: absolute;
  top: .2em;
  left: 0;
  width: .8em;
  height: .8em;
  border-radius: 50%;
  background: #e2a0e6;
  transition: .4s;
  transform: scale(0, 0);
}

input[type="radio"]:checked + .wpcf7-list-item-label::after {
  transform: scale(1, 1);
  border-radius: 50%;
  background: #E2A0E6;
}

.wpcf7-submit {
  display: block;
  margin: 12px auto;
  /* Rectangle 3: */
  background: #E2A0E6;
  color: white;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.5);
  border: none;
  margin-top: 20px;
  padding: 10px 20px 10px 20px;
  font-weight: 800;
}

.wpcf7-submit:hover {
  box-shadow: none;
}

.wpcf7-mail-sent-ok {
  border: none;
}

.hair-menu ul {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 50%;
  min-width: 280px;
  margin: auto;
  padding: 0;
}

.hair-menu li {
  list-style: none;
  width: 50%;
}

.hair-menu {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 700px;
  text-align: center;
  margin: auto;
  padding: 20px;
}

@media screen and (max-width: 500px) {
  .hair-menu {
    width: 100%;
    margin: 0;
  }
}

.hair-menu .menu_ttl {
  width: 100%;
  margin: 0 0 1em;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
}

.hair-menu .list-subject {
  flex-grow: 1;
  font-size: 14px;
  text-align: right;
  padding: 10px 10px 10px 0;
  min-width: 100px;
}

@media screen and (max-width: 500px) {
  .hair-menu .list-subject {
    text-align: right;
    width: 50%;
    min-width: 0;
  }
}

.hair-menu .list-value {
  font-size: 14px;
  text-align: left;
  padding: 10px 0 10px 10px;
  min-width: 100px;
}

@media screen and (max-width: 500px) {
  .hair-menu .list-value {
    width: 45%;
    min-width: 0;
  }
}

.gallery-container {
  max-width: 1200px;
  padding: 20px;
  width: 100%;
  margin: auto;
  text-align: left;
}

@media screen and (max-width: 800px) {
  .gallery-container {
    padding-left: 15px;
    text-align: left;
  }
}

.gallery-image-container {
  display: flex;
  flex-flow: row wrap;
}

.gallery-image-container .image-item {
  display: block;
  width: 23%;
  height: 20vw;
  max-height: 250px;
  margin: 10px 1%;
}

@media screen and (max-width: 800px) {
  .gallery-image-container .image-item {
    width: 31.3%;
    height: 30vw;
    margin: 7px 1%;
  }
}

@media screen and (max-width: 500px) {
  .gallery-image-container .image-item {
    width: 48%;
    height: 45vw;
    margin: 4px 1%;
  }
}

.gallery-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-header-image {
  display: inline;
  max-width: 150px;
  margin: 30px;
}

.header-image {
  height: 80px;
  margin-top: 30px;
}

.flex-container {
  display: flex;
  width: 100%;
}

.text-center {
  text-align: center;
}

.content-header {
  padding: 30px;
  text-align: center;
  width: 100%;
}

.title-hr {
  width: 20%;
  border: 0;
  margin-top: -15px;
  background-position: 50%;
  box-sizing: border-box;
  height: 1px;
  color: #0f0f0f;
  background-image: linear-gradient(90deg, rgba(15, 15, 15, 0), #0f0f0f 50%, rgba(15, 15, 15, 0) 100%);
}

.content-body {
  width: 100%;
}

.content-body table {
  margin-left: auto;
  margin-right: auto;
}

.content-body table td {
  padding: 30px;
  padding-bottom: 10px;
  width: 50%;
}

.content-body table th {
  padding: 30px;
  padding-bottom: 10px;
  text-align: right;
  width: 50%;
}

.menu {
  height: 750px;
  padding-bottom: 80px;
  background-image: url("../images/aporo_background.jpg");
  background-size: cover;
  background-position: 100% 100%;
}

.menu > table {
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
}

.menu td {
  border: solid 1px #010101;
  border-collapse: collapse;
  height: 200px;
  width: 200px;
  background-color: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 1000px) {
  .menu td {
    height: 200px;
    width: 200px;
  }
}

@media screen and (max-width: 800px) {
  .menu td {
    height: 150px;
    width: 150px;
  }
}

@media screen and (max-width: 500px) {
  .menu td {
    height: 120px;
    width: 120px;
  }
}

.menu td a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.menu td a p {
  margin: 0;
  padding-bottom: 4%;
  font-weight: 800;
}

.menu td a small {
  z-index: 2;
  font-size: 16px;
}

@media screen and (max-width: 800px) {
  .menu td a small {
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .menu td a small {
    font-size: 12px;
  }
}

.menu td a {
  z-index: 2;
  height: 100%;
  padding: 0;
  color: #686868;
  font-size: 20px;
}

@media screen and (max-width: 500px) {
  .menu td a {
    font-size: 17px;
  }
}

.menu td a:link {
  color: #686868;
}

.menu td a:visited {
  color: #686868;
}

.menu td a:hover {
  background-color: #E2A0E6;
  opacity: 0.9;
  color: #fff;
}

.menu-bg {
  position: absolute;
  top: 140px;
  right: 0px;
  height: auto;
  z-index: -1;
  width: auto;
}

.index-link-invalid :hover {
  background-color: #686868;
}

.list-container {
  margin-top: 30px;
}

.items_introduction {
  margin-bottom: 2em;
  font-size: 14px;
}

@media screen and (max-width: 500px) {
  .items_introduction {
    font-size: 12px;
  }
}

.itemslist {
  display: flex;
  flex-flow: row wrap;
  list-style-type: none;
  align-items: stretch;
  width: 95%;
  max-width: 800px;
  margin: 0 auto 2em;
  padding: 0;
}

.itemslist .items_item {
  font-size: 14px;
  color: #4A4A4A;
  width: 31%;
  margin: 1%;
  padding: 1em;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 1px 1px 3px #dadada;
}

.itemslist .items_item_ttl,
.itemslist .items_item_price {
  font-weight: 800;
  text-align: center;
}

.itemslist .items_item_ttl {
  margin: .5em 0;
  font-size: 120%;
}

.itemslist .items_item_thumbnail {
  margin-bottom: 1em;
}

.itemslist .items_item_thumbnail img {
  width: 100%;
  height: auto;
  border: 1px solid #dadada;
}

.itemslist .items_item_txt {
  margin-bottom: .5em;
  font-size: 12px;
}

.itemslist .items_item_txt p {
  margin: 0;
}

@media screen and (max-width: 800px) {
  .itemslist {
    max-width: 540px;
  }
  .itemslist .items_item {
    width: 48%;
  }
}

@media screen and (max-width: 500px) {
  .itemslist {
    justify-content: center;
  }
  .itemslist .items_item {
    width: 90%;
    margin: 5%;
  }
}

.list-container {
  margin-top: 30px;
}

.newslist {
  list-style-type: none;
  width: 80%;
  max-width: 800px;
  margin: 0 auto 2em;
  padding: 1em;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 1px 1px 3px #dadada;
}

.newslist .news_item {
  display: block;
  font-size: 14px;
  color: #4A4A4A;
}

.newslist .news_item {
  padding: 1em;
}

.newslist .news_item a {
  display: block;
  color: #686868;
}

.newslist .news_item a:hover {
  color: #E2A0E6;
}

.newslist .news_item + .news_item {
  border-top: 1px solid #dadada;
}

@media screen and (max-width: 500px) {
  .newslist .news_item a:hover {
    color: #686868;
  }
}

.newslist .news_item_ttl {
  margin: .5em 0;
  font-size: 120%;
  font-weight: 800;
}

.newslist .news_item_txt {
  margin-bottom: .5em;
}

.newslist .news_item_txt p {
  margin: 0;
}

.pagenation {
  color: white;
  text-align: center;
  margin-bottom: 10px;
}

.pagenation .page_box {
  display: inline-block;
  width: 76px;
  vertical-align: top;
}

.pagenation .prev, .pagenation .next {
  position: relative;
  display: inline-block;
  text-align: center;
  background-color: #686868;
  font-size: 0;
  width: 30px;
  vertical-align: middle;
}

.pagenation .prev.not_pagenation, .pagenation .next.not_pagenation {
  opacity: 0.3;
}

.pagenation .prev::before, .pagenation .next::before {
  vertical-align: middle;
  font-size: 14px;
  line-height: 30px;
}

.pagenation .prev a, .pagenation .next a {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  top: 0;
}

.pagenation .prev {
  margin-right: 10px;
}

.pagenation .prev::before {
  content: '<';
}

.pagenation .next {
  margin-left: 10px;
}

.pagenation .next::before {
  content: '>';
}

.pagenation .page_num {
  display: inline-block;
  text-align: center;
}

.pagenation .page_num .page-numbers {
  color: white;
  vertical-align: middle;
  display: inline-block;
  width: 30px;
  line-height: 30px;
  margin: 0px 1px;
  background-color: #686868;
}

.pagenation .page_num .page-numbers.current {
  background-color: #E2A0E6;
}

.shopinfo-list {
  display: inline-block;
  text-align: left;
  font-size: 14px;
  margin: auto;
}

.shopinfo-list .list-subject {
  display: inline-block;
  text-align: left;
  padding: 10px;
  min-width: 100px;
  font-weight: bold;
}

@media screen and (max-width: 800px) {
  .shopinfo-list .list-subject {
    width: 100%;
  }
}

.shopinfo-list .list-value {
  display: inline-block;
  text-align: left;
}

.shopinfo-list .list-value a:link {
  color: #686868;
}

.shopinfo-list .list-value a:visited {
  color: #686868;
}

.shopinfo-list .list-value a:hover {
  color: #E2A0E6;
}

@media screen and (max-width: 800px) {
  .shopinfo-list .list-value {
    margin-left: 10px;
  }
}

@media screen and (max-width: 500px) {
  .shopinfo-list .list-value {
    margin-left: 10px;
  }
}

.map-container {
  width: 60%;
  height: 600px;
  max-width: 1260px;
}

@media screen and (max-width: 800px) {
  .map-container {
    width: 90%;
    height: 400px;
  }
}

.l-access-map {
  margin: auto;
  margin-bottom: 20px;
}

.single_news_item {
  width: 95%;
  max-width: 800px;
  margin: auto;
  padding: 1em;
  text-align: left;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 1px 1px 3px #dadada;
}

.single_news_item .single_news_item_date,
.single_news_item .single_news_item_ttl,
.single_news_item .single_news_item_txt {
  margin-bottom: 1em;
}

.single_news_item .single_news_item_ttl {
  font-size: 120%;
  font-weight: 800;
}

@media screen and (max-width: 500px) {
  .single_news_item .single_news_item_ttl {
    font-size: 16px;
  }
}

.single_news_item .single_news_item_txt {
  margin-bottom: 2em;
}

.single_news_item .single_news_item_txt p {
  margin: 0 0 .5em;
  line-height: 1.6;
  letter-spacing: .05em;
}

@media screen and (max-width: 500px) {
  .single_news_item .single_news_item_txt {
    font-size: 14px;
  }
}

.single_news_item img {
  max-width: 100%;
}

.single_news_item a {
  display: block;
  color: #686868;
}

.single_news_item a:hover {
  color: #E2A0E6;
}

.staff-card-container {
  display: flex;
  flex-flow: row wrap;
  width: 95%;
  max-width: 800px;
  margin: auto;
  text-align: left;
}

@media screen and (max-width: 800px) {
  .staff-card-container {
    max-width: 540px;
  }
}

@media screen and (max-width: 500px) {
  .staff-card-container {
    text-align: center;
  }
}

.staff-card {
  width: 31%;
  margin: 1%;
  padding: 1em;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 1px 1px 3px #dadada;
}

@media screen and (max-width: 800px) {
  .staff-card {
    width: 46%;
    margin: 2%;
  }
}

@media screen and (max-width: 500px) {
  .staff-card {
    width: 90%;
    margin: 5%;
  }
}

.staff-content {
  font-size: 14px;
}

.staff-content li {
  list-style: none;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.staff-img-wrap {
  padding-top: 70%;
  /* line-height: 240px; */
  border: 1px solid #ebebeb;
  background: #f9f9f9;
  position: relative;
}

.staff-img {
  width: auto;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
