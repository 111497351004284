.shopinfo-list {
  display: inline-block;
  text-align: left;
  font-size: $content-font-size;
  margin: auto;

  .list-subject {
    display: inline-block;
    text-align: left;
    padding : 10px;
    min-width: 100px;
    font-weight: bold;
    @include mq(md) {
      width: 100%; } }

  .list-value {
    display: inline-block;
    text-align: left;

    a:link {
      color: #686868; }

    a:visited {
      color: #686868; }

    a:hover {
      color: #E2A0E6; }
    @include mq(md) {
      margin-left: 10px; }
    @include mq(sm) {
      margin-left: 10px; } } }

.map-container {
  width: 60%;
  height: 600px;
  max-width: $page-max-width;

  @include mq(md) {
    width: 90%;
    height: 400px; } }


.l-access-map {
  margin: auto;
  margin-bottom: 20px; }
