.pagenation{
  color: white;
  text-align: center;
  margin-bottom: 10px;
  .page_box{
    display: inline-block;
    width: 76px;
    vertical-align: top;
  }
  .prev,.next{
    position: relative;
    display: inline-block;
    text-align: center;
    background-color: $basic-black;
    font-size: 0;
    width: 30px;
    vertical-align: middle;
    &.not_pagenation{
      opacity: 0.3;
	  }
    &::before{
      vertical-align: middle;
      font-size: 14px;
      line-height: 30px;
	  }
    a{
      display: inline-block;
      width: 30px;
      height: 30px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .prev{
    margin-right: 10px;
    &::before{
      content: '<';
	  }
  }
  .next{
    margin-left: 10px;
    &::before{
      content: '>';
	  }
  }

  .page_num{
    display: inline-block;
    text-align: center;
    .page-numbers{
      color: white;
      vertical-align: middle;
      display: inline-block;
      width: 30px;
      line-height: 30px;
      margin: 0px 1px;
      background-color: $basic-black;
    }
    .page-numbers.current{
      background-color: $accent-pink;
	   }
   }
 }
