.staff-card-container {
  display: flex;
  flex-flow: row wrap;
  width: 95%;
  max-width: 800px;
  margin: auto;

  text-align: left;
  @include mq(md) {
    max-width: 540px; }
  @include mq(sm) {
    text-align: center; } }


.staff-card {
  width: 31%;
  margin: 1%;
  padding: 1em;
  background: rgba($white, .8);
  box-shadow: 1px 1px 3px $border-color;

  @include mq(md) {
    width: 46%;
    margin: 2%; }

  @include mq(sm) {
    width: 90%;
    margin: 5%; } }

.staff-content {
  font-size: $content-font-size; }

.staff-content li {
  list-style: none;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px; }

.staff-img-wrap {
   padding-top: 70%;
   /* line-height: 240px; */
   border: 1px solid #ebebeb;
   background: #f9f9f9;
   position: relative; }

.staff-img {
  width: auto;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  height: auto; }
