//htmlタグ自体への編集
html, body {
  margin: 0;
  height: 100%;
  color: $basic-black; }

header, footer {
  z-index: 2;
  a:link {
    color: $basic-black; }

  a:visited {
    color: $basic-black; }

  a:hover {
    color: $accent-pink; } }

h1 {
  font-size: $catch-font-size;
  color: $basic-black;
  padding: 40px;
  margin: 0;
  font-weight: normal; }

h1::after {
  content: ' ';
  position: absolute;
  display: block;
  margin-top: 5px;
  left: 50%;
  width: 50px;
  height: 3px;
  background-color: $accent-pink;
  margin-left: -25px;
  z-index: 0; }

h2 {
  font-size: $catch-font-size;
  color: $basic-black;
  padding: 40px;
  margin: 0;
  font-weight: normal; }

h2::after {
  content: ' ';
  display: block;
  position: absolute;
  margin-top: 5px;
  left: 50%;
  width: 50px;
  height: 3px;
  background-color: $accent-pink;
  margin-left: -25px;
  z-index: -1; }

.h2-body {
  display: inline-block;
  background-color: rgba(246,246,246,0.5);
  width: 100%; }

small {
  font-size: $small-font-size; }

//全ページ共通のパーツ
.wrap {
  width: 100%;
  min-height: calc(100% - 60px); }


.wrap::before,
.wrap::after {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  z-index: -2; }

.wrap::before {
  background: rgba(255,255,255,0.1);
  background-size: cover; }
.wrap::after {
  background: url("../images/aporo_background.jpg");
  opacity: 0.2;
  background-size: cover; }

.main {
  padding-top: $header_height;
  padding-bottom: 30px;

  width: 100%;
  margin: auto;

  text-align: center; }

.center {
  text-align: center; }

.left {
  text-align: left; }

.link-invaild {
  color : $basic-black;
  opacity: 0.4;
  font-size: $small-font-size; }

.link-invaild :hover {
  color : $basic-black; }


//header
.page-header {
  width: 100%;
  height: $header_height;
  display: inline-block;
  padding-top: 15px; }

.page-header a img {
  height: 30px;
  vertical-align: middle;
  margin-left: 30px;
  margin-right: 30px; }

.page-header-menu {
  text-align: center;
  width: 80%;

  @include mq(md) {
    display: none; } }

.page-header-menu li {
  display: inline-block;
  vertical-align: middle;
  font-size: $small-font-size;
  color: #4A4A4A;
  padding-left: 15px;
  clearfix() {} }

.page-header-menu-active {
  border-bottom: solid 3px #E2A0E6;
  padding-bottom: 5px; }


.header-container {
  position: fixed;
  background: #FFFFFF;
  box-shadow: 1px 1px 3px $border-color; }

.headline {
  text-align: center;
  background-color: rgba(246,246,246,0.7);
  margin-bottom: $small-font-size*2;
  padding: $small-font-size 0;
  box-shadow: 1px 1px 3px $border-color; }

.headline-box {
  display: flex;
  align-items: baseline;
  width: 95%;
  max-width: 800px;
  margin: auto; }

.headline-date,
.headline-link {
  font-size: 14px;
  @include mq(sm) {
    font-size: 12px; } }

.headline-title,
.headline-link {
  margin-left: $small-font-size; }

.headline-title {
  max-width: calc(100% - 85px - 40px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @include mq(sm) {
    max-width: calc(100% - 75px - 30px);
    font-size: 14px; } }

.headline-link {
  color: $accent-pink;
  &:hover {
    text-decoration: underline; } }

//responsive header

.responsive-menu {
  display: none;
  position: fixed;
  height: 100%;
  margin-top : $header_height;
  padding: 5%;

  float: right;

  width: 100%;
  background-color: rgba(0,0,0,0.6);
  text-align: center;
  clearfix() {}

  z-index: 20; }

.responsive-menu {
  table {
      border-collapse: collapse;
      margin: auto;
      max-width: 600px;
      max-height: 600px;
      display: inline-block; }
  td {
    text-align: center;
    border: solid 1px rgba(255,255,255,1);
    border-collapse: collapser;
    width: 30vw;
    height: 30vw;
    @media screen and ( orientation: landscape) {
      width: 23vh;
      height: 23vh;
      font-size: 14px; }
    a {
      width: 100%;
      height: 100%;
      font-weight: 800;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }

    a:link {
      color: white; }

    a:visited {
      color: white; }

    a:hover,
    a.active {
      background-color: $light-black;
      opacity: 0.9;
      color: $white; } } }



.responsive-icon {
  display: none;

  @include mq(md) {
    display: inline-block;
    height: $header_height - 20;
    float: right;
    margin-right: 20px; } }

.IsDisplay {
  display: inline;
  margin-left: 0px; }

.IconActive {
  background-color: rgba(104,104,104, 0.21); }


//footer
footer {
  height: $footer_height;
  width: 100%; }

.footer-container {
  background: white;
  width: 100%;
  padding: 2em 0 1em;
  line-height: $footer_height;
  text-align: center;
  font-size: $small-font-size; }

.push {
  height: $footer_height; }
