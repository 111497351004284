.list-container {
  margin-top: 30px; }

.newslist {
  list-style-type: none;
  width: 80%;
  max-width: 800px;
  margin: 0 auto 2em;
  padding: 1em;
  background: rgba($white, .8);
  box-shadow: 1px 1px 3px $border-color;

  .news_item {
    display: block;
    font-size: $content-font-size;
    color: #4A4A4A; }

  .news_item {
    padding: 1em;
    a {
      display: block;
      color: $basic-black; }
    a:hover {
      color: $accent-pink; }
    & + .news_item {
      border-top: 1px solid $border-color; }
    @include mq(sm) {
      a:hover {
        color: $basic-black; } } }

  .news_item_ttl {
    margin: .5em 0;
    font-size: 120%;
    font-weight: 800; }

  .news_item_txt {
    margin-bottom: .5em;
    p {
      margin: 0; } } }

