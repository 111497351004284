.calender-info-text {
  display: inline-block;
  max-width: 800px;
  width: 100%;
  padding: 10px;
  text-align: center;

  @include mq(sm) {
    text-align: center; } }

.calender-wrap {
  width: 95%;
  max-width: 800px;
  height: 800px;
  margin: auto;
  text-align: center;
  @include mq(md) {
    max-width: 500px;
    height: 500px; }
  @include mq(sm) {
    height: 400px; } }

.calender-content {
  width: 80%;
  height: 800px;
  max-width: 800px;
  text-align: center;

  @include mq(md) {
    width: 90%;
    height: 800px; } }
