.on_pc {
  @include mq(md) {
    display: none; } }

.on_tb {
  display: none;
  @include mq(md) {
    display: inherit; }
  @include mq(sm) {
    display: none; } }

.on_sp {
  display: none;
  @include mq(sm) {
    display: inherit; } }
