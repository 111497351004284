.main-header-image {
  display: inline;
  max-width: 150px;
  margin: 30px; }

.header-image {
  height: 80px;
  margin-top: 30px; }

.flex-container {
  display: flex;
  width: 100%; }

.text-center {
  text-align: center; }

.content-header {
  padding: 30px;
  text-align: center;
  width: 100%; }

.title-hr {
  width: 20%;
  border: 0;
  margin-top: -15px;
  background-position: 50%;
  box-sizing: border-box;
  height: 1px;
  $color: #0f0f0f;
  color: $color;
  background-image: linear-gradient(90deg,rgba($color, 0),rgba($color, 1) 50%,rgba($color, 0) 100%); }

.content-body {
  width: 100%; }


.content-body table {
  margin-left: auto;
  margin-right: auto; }

.content-body table td {
  padding: 30px;
  padding-bottom: 10px;
  width: 50%; }


.content-body table th {
  padding: 30px;
  padding-bottom: 10px;
  text-align: right;
  width: 50%; }

.menu {
  height: 750px;
  padding-bottom: 80px;
  background-image: url("../images/aporo_background.jpg");
  background-size: cover;
  background-position: 100% 100%; }

.menu > table {
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  z-index: 2; }

.menu td {
  border: solid 1px rgba(1,1,1,1);
  border-collapse: collapse;
  height: 200px;
  width: 200px;

  @include mq(lg) {
    height: 200px;
    width:  200px; }
  @include mq(md) {
    height: 150px;
    width:  150px; }
  @include mq(sm) {
    height: 120px;
    width: 120px; }

  background-color: rgba(255,255,255,.5); }

.menu td a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; }

.menu td a p {
  margin: 0;
  padding-bottom: 4%;
  font-weight: 800; }

.menu td a small {
  z-index: 2;
  font-size: 16px;
  @include mq(md) {
    font-size: 14px; }
  @include mq(sm) {
    font-size: 12px; } }


.menu td a {
  z-index: 2;
  height: 100%;
  padding: 0;
  color: $basic-black;

  font-size: 20px;
  @include mq(sm) {
    font-size: 17px; } }

.menu td a:link {
  color: $basic-black; }

.menu td a:visited {
  color: $basic-black; }

.menu td a:hover {
  background-color: $accent-pink;
  opacity: 0.9;
  color: $white; }

.menu-bg {
  position: absolute;
  top: 140px;
  right: 0px;
  height: auto;
  z-index: -1;
  width: auto; }


.index-link-invalid :hover {
  background-color: $basic-black; }
