.single_news_item {
  width: 95%;
  max-width: 800px;
  margin: auto;
  padding: 1em;
  text-align: left;
  background: rgba($white,.8);
  box-shadow: 1px 1px 3px $border-color;
  .single_news_item_date,
  .single_news_item_ttl,
  .single_news_item_txt {
    margin-bottom: 1em; }
  .single_news_item_ttl {
    font-size: 120%;
    font-weight: 800;
    @include mq(sm) {
      font-size: 16px; } }
  .single_news_item_txt {
    margin-bottom: 2em;
    p {
      margin: 0 0 .5em;
      line-height: 1.6;
      letter-spacing: .05em; }
    @include mq(sm) {
      font-size: 14px; } }
  img {
    max-width: 100%; }
  a {
    display: block;
    color: $basic-black; }
  a:hover {
    color: $accent-pink; } }
