.hair-menu {
  ul {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    width: 50%;
    min-width: 280px;
    margin: auto;
    padding: 0; }
  li {
    list-style: none;
    width: 50%; } }

.hair-menu {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 700px;
  text-align: center;
  margin: auto;
  padding: 20px;
  @include mq(sm) {
    width: 100%;
    margin: 0; }

  .menu_ttl {
    width: 100%;
    margin: 0 0 1em;
    font-size: $content-font-size + 2px;
    font-weight: 800;
    text-align: center; }

  .list-subject {
    flex-grow: 1;
    font-size: $content-font-size;
    text-align: right;
    padding : 10px 10px 10px 0;
    min-width: 100px;

    @include mq(sm) {
      text-align: right;
      width: 50%;
      min-width: 0; } }

  .list-value {
    font-size: $content-font-size;
    text-align: left;
    padding : 10px 0 10px 10px;
    min-width: 100px;

    @include mq(sm) {
      width: 45%;
      min-width: 0; } } }


.gallery-container {
  max-width: 1200px;
  padding: 20px;
  width: 100%;
  margin: auto;
  text-align: left;
  @include mq(md) {
    padding-left: 15px;
    text-align: left; } }


.gallery-image-container {
  display: flex;
  flex-flow: row wrap;
  .image-item {
    display: block;
    width: 23%;
    height: 20vw;
    max-height: 250px;
    margin: 10px 1%;
    @include mq(md) {
      width: 31.3%;
      height: 30vw;
      margin: 7px 1%; }
    @include mq(sm) {
      width: 48%;
      height: 45vw;
      margin: 4px 1%; } }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover; } }
