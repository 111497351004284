.list-container {
  margin-top: 30px; }

.items_introduction {
  margin-bottom: 2em;
  font-size: 14px;
  @include mq(sm) {
    font-size: 12px; } }

.itemslist {
  display: flex;
  flex-flow: row wrap;
  list-style-type: none;
  align-items: stretch;
  width: 95%;
  max-width: 800px;
  margin: 0 auto 2em;
  padding: 0;

  .items_item {
    font-size: $content-font-size;
    color: #4A4A4A;
    width: 31%;
    margin: 1%;
    padding: 1em;
    background: rgba($white, .8);
    box-shadow: 1px 1px 3px $border-color; }

  .items_item_ttl,
  .items_item_price {
    font-weight: 800;
    text-align: center; }

  .items_item_ttl {
    margin: .5em 0;
    font-size: 120%; }

  .items_item_thumbnail {
    margin-bottom: 1em;
    img {
      width: 100%;
      height: auto;
      border: 1px solid $border-color; } }

  .items_item_txt {
    margin-bottom: .5em;
    font-size: 12px;
    p {
      margin: 0; } }

  @include mq(md) {
    max-width: 540px;
    .items_item {
      width: 48%; } }

  @include mq(sm) {
    justify-content: center;
    .items_item {
      width: 90%;
      margin: 5%; } } }
