
.contact-wrap {
  display: inline-block;
  font-size: 0;
  width: 80%;
  text-align: center;
  max-width: $page-max-width;
  font-size: $content-font-size; }


.contact-text {
  text-align: left;
  width: 100%;
  margin: 0 auto;
  max-width: 550px; }

.wpcf7-form {
  text-align: center; }

.wpcf7-form-control-wrap {
  top: 5px; }

.wpcf7-form p {
  padding: 5px;
  padding-top: 20px;
  text-align: left;
  max-width: 550px;
  width: 100%;
  margin: 0 auto; }


.wpcf7-form p label {
  font-size: $content-font-size;
  text-align: left; }



.wpcf7-textarea, .wpcf7-text, .wpcf7-number {
  padding: 6px 10px;
  width: 100%;
  border: 1px solid $border-color;
  background: rgba($white, .8); }

.wpcf7-form input[type="text"]:focus,
.wpcf7-form input[type="number"]:focus,
.wpcf7-form input[type="email"]:focus,
.wpcf7-form input[type="tel"]:focus,
.wpcf7-form textarea:focus {
  outline: 1px solid $accent-pink; }

input[type="radio"] {
  display: none; }

.wpcf7-list-item-label {
  position: relative;
  margin-right: .5em;
  padding-left: 1.5em; }
.wpcf7-list-item-label::before {
  content: "";
  display: block;
  position: absolute;
  top: .2em;
  left: 0;
  width: .8em;
  height: .8em;
  border-radius: 50%;
  z-index: -1;
  transform: scale(0,0);
  background: rgba($accent-pink,.3); }
input[type="radio"]:checked + .wpcf7-list-item-label:before {
  animation: pulsate .8s ease-out; }

@keyframes pulsate {
  0% {
    transform: scale(0, 0); }
  50% {
    opacity: 1; }
  100% {
    transform: scale(3.5, 3.5);
    opacity: 0.0; } }

.wpcf7-list-item-label::after {
  content: "";
  display: block;
  position: absolute;
  top: .2em;
  left: 0;
  width: .8em;
  height: .8em;
  border-radius: 50%;
  background: rgba($accent-pink,1);
  transition: .4s;
  transform: scale(0,0); }

input[type="radio"]:checked + .wpcf7-list-item-label::after {
  transform: scale(1,1);
  border-radius: 50%;
  background: $accent-pink; }

.wpcf7-submit {
  display: block;
  margin: 12px auto;
  /* Rectangle 3: */
  background: $accent-pink;
  color: white;
  box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.50);
  border: none;
  margin-top: 20px;
  padding: 10px 20px 10px 20px;
  font-weight: 800; }

.wpcf7-submit:hover {
  box-shadow: none; }

.wpcf7-mail-sent-ok {
  border: none; }
